
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.stock.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateStock" autocomplete="off">
                <v-layout row wrap>
        
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="items" v-model="stock.item_id" dense  filled outlined item-text="item_name"
                                    item-value="item_id" :return-object="false" :label="$store.getters.language.data.stock.item_name">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="stock_move_types" v-model="stock.stock_move_type_id" dense  filled outlined item-text="stock_move_type_name"
                                    item-value="stock_move_type_id" :return-object="false" :label="$store.getters.language.data.stock.stock_move_type_name">
                        </v-select>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="stock.stock_qty" type="number" :label="$store.getters.language.data.stock.stock_qty" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="stock.stock_price" type="text" :label="$store.getters.language.data.stock.stock_price" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="stock.stock_date" type="date" :label="$store.getters.language.data.stock.stock_date" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="stock.stock_note" type="textarea" :label="$store.getters.language.data.stock.stock_note" dense
                            class="mx-1"  filled outlined  >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success"     type="submit">{{$store.getters.language.data.stock.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from '../../requests/stock.request.js'
    export default {
        data() {
            return {
                stock: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            items(){
                return this.$store.getters.items_list
            },
            
            stock_move_types(){
                return this.$store.getters.stock_move_types_list
            },
        },
        mounted(){
            this.id = this.$route.params.id
            if(this.$store.state.store_initialized) {
                this.getOneStock()
            }
        },
        methods: {
            getOneStock() {
                this.loading = true
                requests.getOneStock(this.id).then(r =>{
                    if (r.status == 200) {
                        this.stock = r.data.row
                        this.stock.stock_date = new Date(r.data.row.stock_date).toISOString().split('T')[0];
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateStock() {
                this.loading = true
                requests.updateStock(this.id,{
                    stock_id: this.stock.stock_id,
                    item_id: this.stock.item_id,
                    stock_move_type_id: this.stock.stock_move_type_id,
                    stock_qty: this.stock.stock_qty,
                    stock_price: this.stock.stock_price,
                    stock_date: this.stock.stock_date,
                    stock_note: this.stock.stock_note,
                    stock_invoice_number: this.stock.stock_invoice_number
                }).then(r => {
                    if (r.status == 200) {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.update_success,
                            color: 'success'
                        }
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                    this.loading = false
                })
                .finally(() => {
                    this.loading = false
                })
            },
        },
    }
</script>
                    