
import axios from 'axios';
export default {
    
        async createStock(params)  {
            return await axios.post(`stock/create` , params)
        },
        async createStockList(params)  {
            return await axios.post(`stock/create/list` , params)
        },
        async updateStockColumn(column , value , data)  {
            return await axios.put(`stock/update_list?${column}=${value}` , data)
        },
        async deleteStockList(list)  {
            return await axios.delete(`stock/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportStock(columns)  {
            return await axios.get(`stock/report?${columns}`)
        },
        async getAllStock()  {
            return await axios.get(`stock/all`)
        },
        async getOneStock(stock_id)  {
            return await axios.get(`stock/all/${stock_id}`)
        },
        async getStockByColumn(column , value)  {
            return await axios.get(`stock/filter?column=${column}&value=${value}`)
        },
        async deleteStock(stock_id)  {
            return await axios.delete(`stock/delete/${stock_id}`)
        },
        async updateStock(stock_id , params)  {
            return await axios.put(`stock/update/${stock_id}` , params)
        },
        async updateList(params, date)  {
            return await axios.put(`stock/update_list` , {
                data: params,
                date
            })
        }

}
        